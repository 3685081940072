<script lang="ts" setup>
import type { ComponentOptions, ConcreteComponent } from 'vue'
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import Flicking from '@egjs/vue3-flicking'
import { Fade, Parallax, Perspective } from '@egjs/flicking-plugins'
import '@egjs/vue3-flicking/dist/flicking.css'
import type { FlickingOptions, WillChangeEvent } from '@egjs/vue3-flicking'
import type { Flag } from '~/types/assets'
import type { Theme } from '~/types/theme'

const props = defineProps<{
  options: Array<Theme | Flag>
  component: ConcreteComponent
  slideProps?: Record<string, unknown>
}>()

const emits = defineEmits<{ (e: 'onSelect', selection: Theme | Flag): void }>()

const flicking = ref<Flicking | null>(null)
const activeIndex = ref(0)

const plugins = [new Fade(), new Parallax(), new Perspective({ rotate: 1 })]

const slider: Partial<FlickingOptions & ComponentOptions> = {
  defaultIndex: activeIndex.value,
  viewportTag: 'div',
  cameraTag: 'ul',
  cameraClass: '',
  circular: true,
  plugins
}

function onChange({ index }: Pick<WillChangeEvent, 'index'>) {
  activeIndex.value = index
  emits('onSelect', props.options[index])
}

function playIndex(index: number): void {
  flicking.value?.moveTo(index)
}

onMounted(() => onChange({ index: 0 }))
</script>

<template lang="pug">
Flicking.wheel-picker(
  v-bind="slider"
  ref="flicking"
  :options="{ circular: true }"
  :prevent-events-before-init="true"
  :hide-before-init="true"
  @will-change="onChange"
)
  li.wheel-picker__option(
    v-for="(slide, index) in options"
    :key="`slide-${index}`"
    @click="playIndex(index)"
  )
    Component(
      :is="component",
      :theme="slide"
      :flag="slide"
      v-bind="slideProps"
      :class="(index === activeIndex) ? 'active px-8' : ''"
    )
</template>

<style lang="sass" scoped>
.wheel-picker
  @apply py-4 w-full
  // li.wheel-picker__option
  .active
    @apply relative
    &:after
        @apply -top-2 -right-2 -left-2 -bottom-2 absolute border-2 border-white z-50
</style>
